const swedish = {
  Video: 'Video',
  'Contact us': 'Kontakta oss',
  About: 'Om Lokate',
  'Connect with Us': 'Följ oss',
  'Subscribe to Our Mailing List': 'Prenumerera på våra utskick',
  Subscribe: 'Prenumerera',
  AboutUsCopy01:
    'Tveka inte att höra av dig till oss om du har frågor eller vill veta mer om Lokate och hur vi kan hjälpa er med det dagliga leveransflödet.',
  AboutUsCopy02:
    'Fyll i formuläret så kommer vi höra av oss till dig så snart vi bara kan. ',
  AboutUsCopy03:
    'Vill du veta senaste nytt om Lokate klicka i "Håll mig uppdaterad", så missar du ingenting.',
  AboutUsCopy04:
    'Tack för att du besöker oss. Vi ser fram emot att höra mer från dig!',
  'First Name': 'Förnamn',
  'Last Name': 'Efternamn',
  'I would like a demo': 'Kontakta mig för en demo',
  Email: 'Email',
  'Please keep me updated': 'Håll mig uppdaterad',
  Submit: 'Skicka',
  VideoBlurb: 'Här kan du se hur Lokate underlättar ditt dagliga leveransflöde',
  AboutLokateDescription:
    'Vi ser utmaningarna med bygglogistik och hur svårt det kan vara att hålla koll på flödet till byggarbetsplatser. Lokate är en digital tjänst som specialiserar sig på att förbättra leveransflödet för hela ekosystemet inom bygglogistik. ',
  'For Site Managers': 'Byggledare och Arbetsledare',
  SiteManagerDescription:
    'Som ansvarig över en byggarbetsplats kan du med hjälp av Lokate se alla inkommande materialleveranser till byggarbetsplatsen. Du kan lätt kontakta både leverantör och förare genom appen och se till att ni är förberedda på de leveranser som kommer. Med hjälp av Lokate kan ni spara både tid och pengar.',
  'For Drivers': 'Förare',
  DriverDescription:
    'Du som förare, kan med Lokate få hjälp att hitta rätt person och plats för dina leveranser. Byggarbetsplatsen kommer alltid vara redo att ta emot dig och hjälpa dig vid lossning. ',
  'For Suppliers': 'Leverantörer',
  SupplierDescription:
    'Till dig som levererar material och andra hjälpmedel till byggarbetsplatser kan med Lokate se status på dina leveranser samt få direkt feedback på om något gick fel. Du kan lätt hålla koll på dina utgående leveranser och enkelt kontakta de personer som leveranserna berör. Mindre detektivarbete att lokalisera utgående leveranser och inte minst nöjdare kunder. ',
  'Your time is valuable': 'Din tid är dyrbar',
  'We want to help you save it': 'Vi vill hjälpa dig att spara den',
  BottomBlurb: 'Lokate är ett eget varumärke utvecklat inom Volvokoncernen. ',
  TopBlurb:
    'Vi utvecklar en tjänst för att förbättra gods- och trafikflöden för dig inom bygglogistik. Vi tror att det blir enklare för dig och andra inom samma ekosystem om ni kan följa godset i realtid. På det viset kan alla spara tid och använda sina resurser mer effektivt. ',
  'Soon available on': 'Finns snart att ladda ner på',
  'Find out more': 'Mer om Lokate',
  'Keep up to date': 'Kontakta oss',
  'Questions?': 'Frågor?',
  PrivacyPolicyBlurb:
    'Dina personuppgifter behandlas i enlighet med vår personuppgiftspolicy',
  'Type your message here': 'Skriv ditt meddelande här'
}

const english = {
  Video: 'Video',
  Email: 'Email',
  'Contact us': 'Contact us',
  About: 'About',
  'Connect with Us': 'Connect with Us',
  'Subscribe to Our Mailing List': 'Subscribe to Our Mailing List',
  Subscribe: 'Subscribe',
  AboutUsCopy01: "Hey there! We'd love to hear from you.",
  AboutUsCopy02:
    "If you have any questions or would like to learn more about Lokate and how we can help streamline your transportation logistics, please don't hesitate to reach out to us.",
  AboutUsCopy03:
    'Just fill out the form on the right (below on mobile) and our team will get back to you as soon as possible.',
  AboutUsCopy04:
    "We also welcome you to sign up for our newsletter to stay up-to-date with the latest Lokate news and updates. Don't miss out on the latest in transportation management technology!",
  AboutUsCopy05:
    'Thanks for considering Lokate. We look forward to hearing from you soon!',
  'First Name': 'First Name',
  'Last Name': 'Last Name',
  'I would like a demo': 'Contact me for a demo',
  'Please keep me updated': 'Keep me updated',
  Submit: 'Submit',
  VideoBlurb:
    'See how Lokate can help streamline your operations and deliver the best customer experience',
  AboutLokateDescription:
    "Lokate is a software company that specializes in improving the transportation logistics of construction sites. We understand the unique challenges that construction companies face when it comes to managing the flow of materials from suppliers to job sites, and we've developed a solution that streamlines the entire process.",
  'For Site Managers': 'For Site Managers',
  SiteManagerDescription:
    'Lokate provides site managers with real-time visibility into the delivery of materials, enabling them to track progress and make informed decisions. Site managers can easily communicate with suppliers and drivers to ensure that materials are delivered on time and in the correct quantities. By streamlining the transportation process, Lokate helps site managers save time and reduce costs.',
  'For Drivers': 'For Drivers',
  DriverDescription:
    'Lokate helps drivers navigate the most efficient routes to construction sites, saving time and reducing fuel costs. With real-time updates on traffic and construction, drivers can avoid delays and ensure on-time delivery of materials. Drivers can also communicate easily with site managers and suppliers, improving coordination and reducing errors.',
  'For Suppliers': 'For Suppliers',
  SupplierDescription:
    'Lokate makes it easy for suppliers to manage and track their deliveries to construction sites, ensuring that materials are delivered on time and in the correct quantities. With Lokate, suppliers can easily communicate with drivers and receive real-time updates on the status of their deliveries, improving efficiency and reducing errors.',
  'Your time is valuable': 'Your time is valuable.',
  'We want to help you save it': 'We want to help you save it.',
  BottomBlurb:
    'Lokate is a company that makes material flow into construction sitesmore efficient. Our app calculates driver ETA and providesup-to-date information and notifications to site managers.',
  TopBlurb:
    'Lokate is a digital service that will increase the efficiency for inbound traffic at construction sites, help suppliers and site managers in planning, scheduling, and facilitating the delivery of goods and materials on site.',
  'Soon available on': 'Soon available on',
  'Find out more': 'Find out more',
  'Keep up to date': 'Keep up to date',
  'with Lokate': 'with Lokate',
  'Questions?': 'Questions?',
  PrivacyPolicyBlurb: '',
  'Type your message here':'Type your message here'
}

export const i18nGet = (locale, identifier) => {
  if (locale === 'svSE') {
    return swedish[identifier] ?? ''
  }
  return english[identifier] ?? ''
}

export const locale = 'svSE'
