import React from 'react'
import { i18nGet, locale } from './i18n'
import './App.css'
import PolicyPopup from './PolicyPopup'
import emailjs from 'emailjs-com'

const Nav = () => {
  const [menuOpen, setMenuOpen] = React.useState(false)
  return (
    <nav data-testid={'nav'} className="bg-white shadow-lg sticky top-0 z-50">
      <div className="max-w-8xl mx-auto px-4">
        <div className="flex justify-between">
          <div className="flex space-x-7">
            <div>
              {/* Website Logo */}
              <a href="#" className="flex items-left">
                <img
                  className="h-6 pl-20 mt-4 mb-4"
                  src="./assets/lokate-light-logo.svg"
                  alt="Logo"
                />
              </a>
            </div>
            {/* Primary Navbar items */}
            <div className="hidden md:flex items-center space-x-3"></div>
          </div>
          {/* Secondary Navbar items */}
          <div className="hidden md:flex items-center space-x-3 ">
            {/*
            <a
              href="#video"
              className="nav-link py-4 px-2 text-gray-500 font-semibold hover:text-purple-600 transition duration-300 active:text-purple-600 focus:text-purple-600"
            >
              {i18nGet(locale, 'Video')}
            </a>
              */}
            <a
              href="#contact-us"
              className="nav-link py-4 px-2 text-gray-500 font-semibold hover:text-purple-600 transition duration-300 active:text-purple-600 focus:text-purple-600"
            >
              {i18nGet(locale, 'Contact us')}
            </a>
            <a
              href="#about"
              className="nav-link py-4 px-2 text-gray-500 font-semibold hover:text-purple-600 transition duration-300 active:text-purple-600 focus:text-purple-600"
            >
              {i18nGet(locale, 'About')}
            </a>

            {/*
            <a
              href="#references"
              className="nav-link py-3 px-2 text-gray-500 font-semibold hover:text-purple-600 transition duration-300 active:text-purple-600 focus:text-purple-600"
            >
              References
            </a>
            <a
              href
              className="py-2 px-6 font-medium text-white bg-purple-600 rounded hover:bg-purple-600 transition duration-300"
            >
              Sign Up
            </a>
              */}
          </div>
          {/* Mobile menu button */}
          <div className="md:hidden flex items-center">
            <button
              onClick={() => setMenuOpen(!menuOpen)}
              className="outline-none mobile-menu-button"
            >
              <svg
                className=" w-6 h-6 text-gray-500 hover:text-purple-600 "
                x-show="!showMenu"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      {/* mobile menu */}
      <div className={menuOpen ? 'mobile-menu' : 'hidden mobile-menu'}>
        <ul>
          {/*
          <li className="active">
            <a
              href="lokate.html"
              className="block text-sm px-2 py-4 text-white bg-purple-600 font-semibold"
            >
              Home
            </a>
          </li>
          <li>
            <a
              href="#video"
              className="block text-sm px-2 py-4 hover:bg-purple-600 transition duration-300"
            >
              {i18nGet(locale, 'Video')}
            </a>
          </li>
            */}

          <li>
            <a
              href="#contact-us"
              className="block text-sm px-2 py-4 hover:bg-purple-600 transition duration-300"
            >
              {i18nGet(locale, 'Contact us')}
            </a>
          </li>
          <li>
            <a
              href="#about"
              className="block text-sm px-2 py-4 hover:bg-purple-600 transition duration-300"
            >
              {i18nGet(locale, 'About')}
            </a>
          </li>

          {/*
          <li>
            <a
              href="#references"
              className="block text-sm px-2 py-4 hover:bg-purple-600 transition duration-300"
            >
              References
            </a>
          </li>
          <li>
            <a
              href="#"
              className="block text-sm px-2 py-4 hover:bg-purple-600 transition duration-300"
            >
              Log in
            </a>
          </li>
          <li>
            <a
              href="#r"
              className="block text-sm px-2 py-4 hover:bg-purple-600 transition duration-300"
            >
              Sign up
            </a>
          </li>
            */}
        </ul>
      </div>
    </nav>
  )
}

//not currently used
const References = () => {
  return (
    <section
      data-testid={'references'}
      className="bg-gray-100 py-20"
      id="references"
    >
      <div className="container mx-auto px-6 pb-24">
        <h2 className="text-3xl font-bold text-purple-600 mb-4 font-poppins">
          Our customers
        </h2>
        <p className="text-gray-600 mb-8 font-poppins">
          Our customers have experienced significant improvements in their
          transportation logistics, saving time and reducing costs, by using
          Lokate. We are proud to showcase the success stories of our customers
          and demonstrate how Lokate can transform the transportation management
          process for construction sites.
        </p>
        <p className="text-gray-600 mb-8 font-poppins">
          Read on to learn more about the experiences of our satisfied customers
          and discover how Lokate can improve your transportation logistics.
        </p>
      </div>
      <section className="bg-gray-100 py-10">
        <div className="container mx-auto px-4">
          <div className="grid gap-x-6 lg:gap-x-12 md:grid-cols-3">
            <div className="mb-24 md:mb-0">
              <div className="rounded-lg shadow-lg h-full block bg-white">
                <div className="flex justify-center">
                  <div className="flex justify-center mtm75">
                    <img
                      src="images/reference-3.png"
                      className="rounded-full mx-auto shadow-lg w150"
                      alt=""
                    />
                  </div>
                </div>
                <div className="p-6">
                  <h5 className="text-lg font-bold mb-4">Marta Smith</h5>
                  <p className="mb-6">
                    "Lokate has been an invaluable tool for our construction
                    site, helping us stay organized and efficient."
                  </p>
                  <ul className="list-inside flex mx-auto justify-center">
                    <a
                      href="#contact"
                      className="button font-bold py-3 px-8 rounded-full transition duration-300 ease-in-out hover:bg-purple-600 hover:text-white font-poppins"
                    >
                      Case study
                    </a>
                  </ul>
                </div>
              </div>
            </div>
            <div className="mb-24 md:mb-0">
              <div className="rounded-lg shadow-lg h-full block bg-white">
                <div className="flex justify-center">
                  <div className="flex justify-center mtm75">
                    <img
                      src="images/reference-1.png"
                      className="rounded-full mx-auto shadow-lg w150"
                      alt=""
                    />
                  </div>
                </div>
                <div className="p-6">
                  <h5 className="text-lg font-bold mb-4">Darren Randolph</h5>
                  <p className="mb-6">
                    "Since using Lokate, we've been able to track material flows
                    better and make more informed decisions."
                  </p>
                  <ul className="list-inside flex mx-auto justify-center">
                    <a
                      href="#contact"
                      className="button font-bold py-3 px-8 rounded-full transition duration-300 ease-in-out hover:bg-purple-600 hover:text-white font-poppins"
                    >
                      Case study
                    </a>
                  </ul>
                </div>
              </div>
            </div>
            <div className>
              <div className="rounded-lg shadow-lg h-full block bg-white">
                <div className="flex justify-center">
                  <div className="flex justify-center mtm75">
                    <img
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4ITwA-y5mMtH5FQX3X-HLiDkVq4vKVPiJXg&usqp=CAU"
                      className="rounded-full mx-auto shadow-lg w150"
                      alt=""
                    />
                  </div>
                </div>
                <div className="p-6">
                  <h5 className="text-lg font-bold mb-4">Ayat Black</h5>
                  <p className="mb-6">
                    Lokate has helped us manage our construction site more
                    efficiently, saving us time and money.
                  </p>
                  <ul className="list-inside flex mx-auto justify-center">
                    <a
                      href="#contact"
                      className="button font-bold py-3 px-8 rounded-full transition duration-300 ease-in-out hover:bg-purple-600 hover:text-white font-poppins"
                    >
                      Case study
                    </a>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  )
}

const Footer = () => {
  const handleOnSubmit = (e) => {
    e.preventDefault()
    emailjs
      .sendForm(
        'service_q8szkgn',
        'template_zw22bj6',
        e.target,
        '9ivpV8Gm_PaTBAudF',
      )
      .then(
        (result) => {
          console.log(result.text)
          /*Swal.fire({
            icon: 'success',
            title: 'Message Sent Successfully',
          })*/
          alert('Message sent')
        },
        (error) => {
          console.log(error.text)
          /*Swal.fire({
            icon: 'error',
            title: 'Ooops, something went wrong',
            text: error.text,
          })*/
          alert('Ooops, something went wrong')
        },
      )
    e.target.reset()
  }

  return (
    <footer
      data-testid={'footer'}
      className="bg-gradient-to-r from-purple-600 to-purple-800 py-12"
    >
      <div className="container mx-auto flex flex-wrap justify-between">
        <div className="w-full md:w-1/4 mb-8 md:mb-0 mr-10">
          <a href="#">
            <img
              src="./assets/lokate-white.png"
              alt="Lokate logo"
              className="h-8 mb-4"
            />
          </a>
          <p className="text-gray-300 text-sm leading-loose">
            {i18nGet(locale, 'BottomBlurb')}
          </p>
        </div>
        <div className="w-full md:w-1/4 mb-8 md:mb-0 order-3 md:order-2">
          <h3 className="text-white text-lg font-semibold mb-4">
            {i18nGet(locale, 'Subscribe to Our Mailing List')}
          </h3>
          <form onSubmit={handleOnSubmit} className="flex flex-col">
            <input type="hidden" value="No" name="first-name" />
            <input type="hidden" value="Name" name="last-name" />
            <input type="hidden" value="on" name="updates" />
            <input type="hidden" value="Message" name="message" />
            <label htmlFor="email" className="sr-only">
              Email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="Email"
              className="bg-gray-200 text-gray-800 rounded-full px-4 py-2 mb-4 focus:outline-none focus:ring-2 focus:ring-purple-600"
            />
            <p className="text-white font-poppins italic mb-5 text-sm">
              {i18nGet(locale, 'PrivacyPolicyBlurb')
                .split(' ')
                .map((item, i) =>
                  i <
                  -1 + i18nGet(locale, 'PrivacyPolicyBlurb').split(' ').length
                    ? '' + item
                    : '',
                )
                .join(' ')}
              <PolicyPopup />
            </p>
            <button
              type="submit"
              id="submit"
              name="submit"
              className="bg-white text-purple-600 font-semibold py-2 px-4 rounded-full hover:bg-purple-600 hover:text-white transition duration-300"
            >
              {i18nGet(locale, 'Subscribe')}
            </button>
          </form>
        </div>
        <div className="w-full md:w-1/4 mb-8 md:mb-0">
          <h3 className="text-white text-lg font-semibold mb-4">
            {i18nGet(locale, 'Connect with Us')}
          </h3>
          <ul className="flex space-x-4">
            <li>
              <a
                href="https://www.linkedin.com/company/lokate-io/"
                className="text-gray-300 hover:text-gray-200 transition duration-300"
              >
                LinkedIn
              </a>
            </li>
            {/*
            <li>
              <a
                href="#"
                className="text-gray-300 hover:text-gray-200 transition duration-300"
              >
                Facebook
              </a>
            </li>
            <li>
              <a
                href="#"
                className="text-gray-300 hover:text-gray-200 transition duration-300"
              >
                Twitter
              </a>
            </li>
            <li>
              <a
                href="#"
                className="text-gray-300 hover:text-gray-200 transition duration-300"
              >
                Instagram
              </a>
            </li>
            */}
          </ul>
        </div>
      </div>
      <div className="container mx-auto mt-12 text-center">
        <a
          className="text-gray-400 text-sm hover:text-gray-200 transition duration-300 float-right pr-4 pl-4"
          href="https://www.volvogroup.com/en/tools/privacy/privacy-se.html"
        >
          | Privacy
        </a>
        {/**
         * maintaining central alignment and responsive behaviour by mirroring privacy link on left, zero opacity
         */}
        <p className="cursor-default opacity-0 text-gray-400 text-sm hover:text-gray-200 transition duration-300 float-left pr-4 pl-4">
          | Privacy
        </p>
        <p className="text-gray-400 text-sm">
          © 2023 Lokate. All rights reserved.
        </p>
      </div>
    </footer>
  )
}

const ContactUs = () => {
  const handleOnSubmit = (e) => {
    e.preventDefault()
    emailjs
      .sendForm(
        'service_cfg6lui',
        'template_8d0vhjp',
        e.target,
        'VLW4sBXscqZC-Bbxs',
      )
      .then(
        (result) => {
          console.log(result.text)
          /*Swal.fire({
            icon: 'success',
            title: 'Message Sent Successfully',
          })*/
          alert('Message sent')
        },
        (error) => {
          console.log(error.text)
          /*Swal.fire({
            icon: 'error',
            title: 'Ooops, something went wrong',
            text: error.text,
          })*/
          alert('Ooops, something went wrong')
        },
      )
    e.target.reset()
  }
  return (
    <section data-testid={'contact'} className="bg-white py-20" id="contact-us">
      <div className="container mx-auto px-4 lg:px-20">
        <div className="flex flex-col lg:flex-row justify-between">
          <div className="w-full lg:w-3/4 px-4">
            <div className="w-full p-6 my-0 md:px-12 rounded-2xl shadow-2xl h100pc">
              <div className="flex">
                <h1 className="font-bold text-5xl m-2">
                  {i18nGet(locale, 'Contact us')}                  
                </h1>
              </div>
              <form onSubmit={handleOnSubmit} className="mt-3">
                <div className="grid grid-cols-2 gap-5">
                  <input
                    className="w-full bg-gray-100 text-gray-900 mt-1 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                    type="text"
                    placeholder={i18nGet(locale, 'First Name') + '*'}
                    name="first-name"
                    required
                  />
                  <input
                    className="w-full bg-gray-100 text-gray-900 mt-1 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                    type="text"
                    placeholder={i18nGet(locale, 'Last Name') + '*'}
                    name="last-name"
                    required
                  />
                  <input
                    className="w-full bg-gray-100 text-gray-900 mt-1 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                    type="email"
                    placeholder={i18nGet(locale, 'Email') + '*'}
                    name="email"
                    required
                  />
                  <div className="flex flex-col mt-1 col-span-2">
                    <textarea                      
                      className="w-full bg-gray-100 text-gray-900 mt-1 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                      placeholder={i18nGet(locale, 'Type your message here')}
                      name="message"  
                      rows="2"                                        
                    />
                  </div>
                  <div className="flex flex-col mt-1 col-span-2">
                    <label className="inline-flex items-center">
                      <input
                        type="checkbox"
                        className="form-checkbox text-blue-500"
                        name="demo"
                      />
                      <span className="ml-2 text-gray-700">
                        {i18nGet(locale, 'I would like a demo')}
                      </span>
                    </label>
                    <label className="inline-flex items-center mt-2">
                      <input
                        type="checkbox"
                        className="form-checkbox text-blue-500"
                        name="updates"
                      />
                      <span className="ml-2 text-gray-700">
                        {i18nGet(locale, 'Please keep me updated')}
                      </span>
                    </label>
                  </div>
                </div>
                <p className="text-gray-600 font-poppins italic mt-5 text-sm">
                  {i18nGet(locale, 'PrivacyPolicyBlurb')
                    .split(' ')
                    .map((item, i) =>
                      i <
                      -1 +
                        i18nGet(locale, 'PrivacyPolicyBlurb').split(' ').length
                        ? '' + item
                        : '',
                    )
                    .join(' ')}
                  <PolicyPopup />
                </p>
                <div className="my-2 w-1/2 lg:w-1/4">
                  <button
                    className="button uppercase text-sm font-bold tracking-wide bg-blue-900 text-gray-100 p-3 mt-3 rounded-lg w-full 
                  focus:outline-none focus:shadow-outline"
                    type="submit"
                    name="submit"
                  >
                    {i18nGet(locale, 'Submit')}
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="bg-gradient-to-r from-purple-600 to-purple-800 contact-form-info w-full lg:w-1/3 px-8 py-12 ml-auto primary-color rounded-2xl">
            <div className="flex flex-col text-white">
              <h1 className="font-bold text-4xl my-4">
                {i18nGet(locale, 'Questions?')}
              </h1>
              <p className="mb-6 text-gray-200">
                {i18nGet(locale, 'AboutUsCopy01')}
              </p>
              <p className="mb-6 text-gray-200">
                {i18nGet(locale, 'AboutUsCopy02')}
              </p>
              <p className="text-gray-200">
                {i18nGet(locale, 'AboutUsCopy03')}
              </p>
              {i18nGet(locale, 'AboutUsCopy04').trim() != '' && (
                <p className="text-gray-200">
                  {i18nGet(locale, 'AboutUsCopy04')}
                </p>
              )}
              {i18nGet(locale, 'AboutUsCopy05').trim() != '' && (
                <p className="text-gray-200">
                  {i18nGet(locale, 'AboutUsCopy05')}
                </p>
              )}
              <div className="flex my-4 w-full justify-center">
                <div className="flex flex-row items-center justify-center">
                  <i className="fab fa-linkedin -f fa-3x m-4 transform hover:scale-110 hover:text-blue-500" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const Video = () => {
  return (
    <section data-testid={'video'} className="bg-gray-100 py-20" id="video">
      <div className="container mx-auto px-4">
        <h6 className="text-gray-700 text-xl pb-6 font-semibold text-center">
          {i18nGet(locale, 'VideoBlurb')}
        </h6>
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg">
          <div className="rounded-t mb-0 px-6 py-6">
            <div className="text-center flex justify-between"></div>
          </div>
          <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
            <div className="w-full h-0 relative videoContainer">
              <iframe
                src="https://player.vimeo.com/video/811311347?h=2972c59326&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameBorder="0"
                nonce=""
                allow="autoplay; fullscreen; picture-in-picture"
                className="w-full h-full absolute inset-0"
                allowFullScreen
                title="Lokate"
              ></iframe>
              {/*
              
              <video
                className="w-full h-full absolute inset-0"
                src="./assets/lokate.mp4"
                controls
              >
                Your browser does not support the video tag.
              </video>
                */}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const About = () => {
  return (
    <section data-testid={'about'} className="bg-gray-100 py-20" id="about">
      <div className="container mx-auto px-6 pb-24">
        <h2 className="text-3xl font-bold text-purple-600 mb-4 font-poppins">
          {i18nGet(locale, 'About')}
        </h2>
        <p className="text-gray-600 mb-8 font-poppins">
          {i18nGet(locale, 'AboutLokateDescription')}
        </p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <div className="mb-4">
              <img
                src="./assets/site.png"
                alt="Site"
                className="w-full rounded-lg shadow-lg"
              />
            </div>
            <h3 className="text-xl font-bold text-purple-600 mb-4 font-poppins">
              {i18nGet(locale, 'For Site Managers')}
            </h3>
            <p className="text-gray-600 font-poppins">
              {i18nGet(locale, 'SiteManagerDescription')}
            </p>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-lg">
            <div className="mb-4">
              <img
                src="./assets/driver.png"
                alt="Driver"
                className="w-full rounded-lg shadow-lg"
              />
            </div>
            <h3 className="text-xl font-bold text-purple-600 mb-4 font-poppins">
              {i18nGet(locale, 'For Drivers')}
            </h3>
            <p className="text-gray-600 font-poppins">
              {i18nGet(locale, 'DriverDescription')}
            </p>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-lg">
            <div className="mb-4">
              <img
                src="./assets/supplier.png"
                alt="Supplier"
                className="w-full rounded-lg shadow-lg"
              />
            </div>
            <h3 className="text-xl font-bold text-purple-600 mb-4 font-poppins">
              {i18nGet(locale, 'For Suppliers')}
            </h3>
            <p className="text-gray-600 font-poppins">
              {i18nGet(locale, 'SupplierDescription')}
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

const TopSection = () => {
  return (
    <section
      data-testid={'hero'}
      className="bg-gradient-to-r from-purple-600 to-purple-800 pt-10 pb-7"
    >
      <div className="container mx-auto flex flex-col md:flex-row items-center">
        <div className="md:w-1/2 order-2 md:order-1 pr-20">
          <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold text-white font-poppins mb-4 pt-10">
            {i18nGet(locale, 'Your time is valuable')}
            {locale === 'svSE' ? <br /> : '. '}
            {i18nGet(locale, 'We want to help you save it')}
            {locale === 'svSE' ? '' : '.'}
          </h2>
          <p className="text-white text-lg mb-8 font-poppins pt-10 pb-10">
            {i18nGet(locale, 'TopBlurb')}
          </p>
          <a
            href="#about"
            className="button font-bold py-3 px-8 rounded-full transition duration-300 ease-in-out hover:bg-purple-600 hover:text-white font-poppins"
          >
            {i18nGet(locale, 'Find out more')}
          </a>
          <div className="flex flex-col justify-center items-start pt-8">
            <p className="text-white text-lg mb-2 font-poppins">
              {i18nGet(locale, 'Soon available on')}
            </p>
            <div className="flex items-center">
              <img
                className="ml-0 w-36 h-14 mx-2 mt-2"
                src="./assets/apple.svg"
                alt="Download on the App Store"
              />
              <img
                className="ml-0 w-36 h-14 mx-2 mt-2"
                src="./assets/android.svg"
                alt="Get it on Google Play"
              />
            </div>
          </div>
        </div>
        <div className="md:w-1/2 order-1 md:order-2 mb-10 md:mb-0 flex flex-col md:flex-row items-center justify-between py-6">
          <img
            className="object-right"
            src="./assets/hero.png"
            alt="Construction Site"
          />
        </div>
      </div>
    </section>
  )
}

const Body = () => {
  return (
    <div>
      <Nav />
      <TopSection />
      <Video />
      <ContactUs />
      <About />
      {/*
        <References />
        */}
      <Footer />
    </div>
  )
}

function App() {
  return <Body />
}

export default App
